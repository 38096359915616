import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { layoutStyles } from '../style/layoutStyles';
import SideBar from "../components/sideBar"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
<div css={layoutStyles} id="content">
        <div className="grid">
            <div id="sectionPage" className="col-1-1">
              <div id="article" className="col-9-12">
              <h1>The page you're looking for could not be found​</h1>

              <div>

              Here are a few suggestions to help you find the page you are looking for:
              <ul>
                <li>try using our search on the right-hand side of every page</li>
                <li>go to the homepage and browse the site by category or sub-category</li>
                <li>use the navigation tabs at the top of every page</li>
              </ul>
              </div>
  
              </div>

              <div id="aside" className="col-3-12">
                <SideBar FullWidth={true} />
              </div>

          </div>
        </div>
        <div className="whitespacer"></div>
      </div>

  </Layout>
)

export default NotFoundPage
